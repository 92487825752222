<template>
    <div class="row">
        <div class="col-12">
            <div class="choose-logo-content row">
                <div @scroll="scrollLogotypes($event)"
                     class="col-md-4 left-content">
                    <transition name="slideLeft">
                        <ul v-show="previewsIsReady">
                            <li v-for="(url, hash) in previews"
                                :class="{'active-preview': (hash === activeHash)}" class="logo-preview-item">
                                <a href="#"
                                   @click.prevent="clickPreview(url, hash)"
                                   :hash="hash"
                                   class="logo-preview-image"
                                >
                                    <img :src="url" alt="logo" width="300" height="auto">
                                    <logo-hover :ref="'logo-hover-'+hash" :preview-url="url"></logo-hover>
                                </a>
                                <a :href="getEditUrl(hash)" class="edit-link-in-preview">Edit</a>
                            </li>
                        </ul>
                    </transition>
                </div>
                <div class="col-md-8 right-content">
                    <preview ref="preview-choose-logo"></preview>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';
    import appMixin from '../mixins/app-mixin';

    export default {
        name: 'choose-logo',
        mixins: [appMixin],

        data() {
            return {
                previews: {},
                activeHash: null,
                isLoaded: false,
                previewsIsReady: false,
                hasMore: true,
                transitionName: 'slideRight',
                states: {
                    component: {
                        mounted: false,
                    },
                    preview: {
                        updated: false,
                    },
                },

            };
        },

        mounted() {
            this.loadPreviews().then(() => {
                this.setFirstPreview();
                this.initTippy();
                this.$nextTick(() => {
                    this.states.component.mounted = true;
                });
            });
        },

        methods: {
            loadPreviews() {
                // Previews not loaded
                this.isLoaded = false;

                return new Promise((resolve) => {

                    // Get logos array hash
                    let loadedLogos = Object.keys(this.previews);

                    return axios.post(route('logotypes.previews.list.get'), {
                        loaded_logos: loadedLogos,
                    }).then((response) => {

                        if (response.data.length === 0) {
                            this.hasMore = false;
                        }

                        this.previews = Object.assign({}, this.previews, response.data);

                        setTimeout(() => {
                            this.isLoaded = true;
                            this.previewsIsReady = true;
                        });

                        return resolve();
                    });
                });
            },

            setFirstPreview() {
                let firstHash = Object.keys(this.previews)[0];
                let firstPreviewUrl = this.previews[Object.keys(this.previews)[0]];

                this.clickPreview(firstPreviewUrl, firstHash).then(() => {
                    // Change transition after component loading
                    this.$nextTick(() => {
                        this.transitionName = 'fade';
                    });
                });
            },

            clickPreview(previewUrl, hash) {
                return new Promise((resolve, reject) => {
                    this.setEditUrl(hash);
                    this.setLogoToPreview(previewUrl);
                    this.setActiveHash(hash);

                    return resolve();
                });
            },

            setEditUrl(hash) {
                let editUrl = this.getEditUrl(hash);

                EventBus.$emit('logotype.set.edit-url', editUrl);
            },

            getEditUrl(hash) {
                return route('logo.choose', hash);
            },

            setActiveHash(hash) {
                this.activeHash = hash;
            },

            setLogoToPreview(preview) {
                this.states.preview.updated = true;

                this.$nextTick(() => {
                    // Replace preview
                    EventBus.$emit('logotype.preview.set', {
                        preview: preview,
                    });

                    // Redraw component with animation
                    this.states.preview.updated = false;
                });
            },

            initTippy() {
                const self = this;

                setTimeout(() => {
                    tippy('a.logo-preview-image', {
                        duration: 0,
                        placement: 'right',
                        theme: 'light',
                        appendTo: 'parent',
                        boundary: 'preventOverflow',
                        distance: -31,
                        flipOnUpdate: true,
                        hideOnClick: false,
                        animation: 'fade',
                        arrow: false,
                        zIndex: 1,
                        content(reference) {
                            // Return preview image by hash
                            const hash = reference.getAttribute('hash');
                            const ref = self.$refs['logo-hover-' + hash];

                            if (ref.length > 0) {
                                return ref[0].$el;
                            }
                        },
                    });
                });
            },

            scrollLogotypes(evt) {
                let element = evt.target;
                let offsetTrigger = 400;
                let isMobile = this.isMobile();

                // Config with element props
                let config = {
                    axis: isMobile ? 'scrollLeft' : 'scrollTop',
                    offsetIndent: isMobile ? 'offsetWidth' : 'offsetHeight',
                    scrollIndent: isMobile ? 'scrollWidth' : 'scrollHeight',
                };

                let isScrolled = Math.abs((element[config.offsetIndent] + element[config.axis]) - element[config.scrollIndent]) < offsetTrigger;

                if (isScrolled && this.isLoaded && this.hasMore) {
                    this.loadPreviews().then(() => {
                        this.initTippy();
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .logo-preview-image {
        box-shadow: 0 0 15px -3px rgb(207, 207, 207);
        align-items: flex-start;
    }

    .logo-preview-item {
        .edit-link-in-preview {
            display: none;
            position: absolute;
            top: 6px;
            left: 10px;
            color: #8f8f8f;
        }

        &:hover {
            .edit-link-in-preview {
                display: block;
            }
        }
    }

    .choose-logo-content {
        display: flex;
        height: calc(100vh - 140px);
        margin: 70px 0 0;
    }

    .left-content {
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow-y: hidden;
        overflow-x: auto;
        max-height: calc(100vh - 140px);

        @media (max-width: 767px) {
            order: 1;
            margin: 0 auto 100px;
        }

        @media (min-width: 768px) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .left-content ul {
        margin: 0;
        padding: 0;
        white-space: nowrap;

        @media (min-width: 768px) {
            flex-wrap: wrap;
            list-style-type: none;
            display: flex;
            justify-content: space-around;
        }
    }

    .left-content li {
        width: 240px;
        border-radius: 5px;

        margin: 0 15px 0 0;
        padding: 0;
        display: inline-block;
        position: relative;

        @media (min-width: 768px) {
            width: 100%;
            display: block;
            margin: 0 0 15px;
        }

        &:hover {
            background-color: #f3f5ff;
        }

        .logo-preview-image {
            display: flex;
            padding: 10px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .active-preview {
        background-color: #f3f5ff;
    }

    .left-content li.selected {
        border: 2px solid #3A58F9;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.11);
    }

    .right-content {
        padding: 0;
        display: flex;
        overflow: auto;
    }
</style>
