import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import appMixin from '../mixins/app-mixin';

export default {
    mixins: [appMixin],

    data() {
        return {
            purchases: {
                types: {
                    logotype: 'logotype',
                    package: 'package',
                },
            },
        };
    },

    created() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.placeholderForPackageButton();
                this.placeholderForLogotypeButton();
            }, 1000);
        });
    },

    methods: {
        downloadLogo(logotype) {
            if (!logotype || !logotype.hash) return;

            this.downloadGaEvent(logotype, this.purchases.types.logotype);

            // Spinner for buttons
            this.states.buttons.download.available = false;

            Requester.get(route('download.logotype', logotype.hash)).then((response) => {
                if (response.data.type === this.http.statuses.error) {
                    this.notification({
                        title: response.data.error,
                        type: response.data.status,
                        message: response.data.error,
                    });

                    return;
                }

                if (response.data.isPurchased) {
                    const linkSource = response.data.logotype;
                    const downloadLink = document.createElement('a');
                    const fileName = 'logotype.png';

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    this.states.buttons.download.available = true;
                } else {
                    // Redirect to server for this purchase or downloading
                    window.location.href = response.data.paymentUrl;
                }
            });
        },

        downloadPackage(logotype) {
            this.downloadGaEvent(logotype, this.purchases.types.package);

            // Spinner for buttons
            this.states.buttons.download.available = false;

            // Redirect to server for this purchase or downloading
            window.location.href = route('download.package', logotype.hash);
        },

        placeholderForPackageButton() {
            // Show placeholder for editor page
            tippy('.download-package', {
                placement: 'top',
                theme: 'light',
                animation: 'fade',
                trigger: 'mouseenter',
                content(reference) {
                    return '<ul class="benefits-on-purchase-button">' +
                            '<li>High-quality logo images</li>' +
                            '<li>Resizable vector SVG/PDF files</li>' +
                            '<li>Social media assets (Facebook, Twitter, etc.)</li>' +
                            '<li>Font names & color palette</li>' +
                            '<li>Print-ready</li>' +
                            '</ul>';
                },
            });
        },

        placeholderForLogotypeButton() {
            tippy('.download-logotype', {
                placement: 'top',
                theme: 'light',
                animation: 'fade',
                trigger: 'mouseenter',
                content(reference) {
                    return '<ul class="benefits-on-purchase-button">' +
                            '<li>High quality PNG logotype</li>' +
                            '</ul>';
                },
            });
        },
    },
};