<template>
    <div class="logo-hover-wrapper" v-if="previewUrl">
        <div class="logo-hover-container" v-for="color in colors">
            <span class="logo-hover-color" :style="{background: color}"></span>
        </div>
    </div>
</template>

<script>
    import Vibrant from 'node-vibrant';

    export default {
        name: 'logo-hover',

        props: {
            previewUrl: String,
        },

        data() {
            return {
                previews: {},
                colors: [],
            };
        },

        mounted() {
            this.setColors();
        },

        methods: {
            setColors() {
                setTimeout(() => {
                    Vibrant.from(this.previewUrl).getSwatches().then((swatches) => {
                        // Set colors
                        for (var swatch in swatches) {
                            if (swatches.hasOwnProperty(swatch) && swatches[swatch]) {
                                this.colors.push(swatches[swatch].getHex());
                            }
                        }
                    });
                });
            },
        },
    };
</script>

<style lang="scss">
    .left-content {
        .logo-hover-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .logo-hover-container {
            display: flex;
            height: 100%;

            .logo-hover-color {
                align-items: center;
                justify-content: center;
                min-width: 30px;
                display: flex;
            }
        }
        .tippy-content {
            height: 100%;
            padding: 0;
        }

        .tippy-tooltip {
            padding: 0;
            border-radius: 0 5px 5px 0;
            overflow: hidden;
        }
        .tippy-popper {
            bottom: 0;
            display: flex;
        }
    }
</style>