<template>
  <transition name="slideDown">
    <header v-show="isMounted">
      <div class="logo">
        <a href="/" title="Logovate" hreflang="en">
          <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_3918_13166" fill="white">
              <rect width="62" height="62" rx="1"/>
            </mask>
            <rect width="62" height="62" rx="1" fill="white" stroke="#63A7F7" stroke-width="6"
                  mask="url(#path-1-inside-1_3918_13166)"/>
            <path
              d="M11.4805 8.58594H18.8984L22.9766 19.7773L26.75 8.58594L29.8086 8.57422L23.7031 26L18.1836 25.9883L11.4805 8.58594ZM37.0039 12.9805L34.9883 8.58594H42.125L50.6562 26.0117H43.0039L41.7148 23.2227H34.8477L33.5703 26.0117H30.6055L37.0039 12.9805ZM40.4844 20.5391L38.2812 15.7578L36.0898 20.5391H40.4844Z"
              fill="#3C3C3C"/>
            <path
              d="M19.2852 38.2578H14.3164V35.5742H31.0273V38.2578H26.0586V53H19.2852V38.2578ZM33.5703 35.5859H48.2891V38.2695H40.3555L40.3438 42.9688H46.4141V45.6523H40.3438V50.3164H48.2891V53H33.5703V35.5859Z"
              fill="#3C3C3C"/>
          </svg>
        </a>
      </div>
      <div class="steps">
        <a :href="steps.edit.route || steps.choose.route"
           :class="{current: isActive(steps.choose), success: isSuccess(steps.choose)}"
           title="Choose the logo you like the most"
           hreflang="en"
        >
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.8056 13.1944 0 8.5 0C3.80557 0 0 3.8056 0 8.5C0 13.1944 3.80557 17 8.5 17ZM12.7071 7.70709C13.0976 7.31659 13.0976 6.68341 12.7071 6.29291C12.3166 5.90234 11.6834 5.90234 11.2929 6.29291L7 10.5858L5.70711 9.29291C5.31659 8.90234 4.68341 8.90234 4.29289 9.29291C3.90237 9.68341 3.90237 10.3166 4.29289 10.7071L6.29289 12.7071C6.68341 13.0977 7.31659 13.0977 7.70711 12.7071L12.7071 7.70709Z"/>
          </svg>
          1. choose
        </a>
        <a :href="steps.edit.route"
           :class="{current: isActive(steps.edit), success: isSuccess(steps.edit)}"
           title="Update the logo to fit your needs"
           hreflang="en"
        >
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.8056 13.1944 0 8.5 0C3.80557 0 0 3.8056 0 8.5C0 13.1944 3.80557 17 8.5 17ZM12.7071 7.70709C13.0976 7.31659 13.0976 6.68341 12.7071 6.29291C12.3166 5.90234 11.6834 5.90234 11.2929 6.29291L7 10.5858L5.70711 9.29291C5.31659 8.90234 4.68341 8.90234 4.29289 9.29291C3.90237 9.68341 3.90237 10.3166 4.29289 10.7071L6.29289 12.7071C6.68341 13.0977 7.31659 13.0977 7.70711 12.7071L12.7071 7.70709Z"/>
          </svg>
          2. edit
        </a>
        <a @click="downloadLogo(logotype)"
           v-loading="!states.buttons.download.available"
           :disabled="!states.buttons.download.available"
           :class="{current: isActive(steps.buy), success: isSuccess(steps.buy)}"
           title="Get the designed logo!"
           hreflang="en"
        >
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.8056 13.1944 0 8.5 0C3.80557 0 0 3.8056 0 8.5C0 13.1944 3.80557 17 8.5 17ZM12.7071 7.70709C13.0976 7.31659 13.0976 6.68341 12.7071 6.29291C12.3166 5.90234 11.6834 5.90234 11.2929 6.29291L7 10.5858L5.70711 9.29291C5.31659 8.90234 4.68341 8.90234 4.29289 9.29291C3.90237 9.68341 3.90237 10.3166 4.29289 10.7071L6.29289 12.7071C6.68341 13.0977 7.31659 13.0977 7.70711 12.7071L12.7071 7.70709Z"/>
          </svg>
          3. download
        </a>
      </div>
      <div class="right-content">
        <div class="nav">
          <div class="nav-login-wrapper">
            <top-right-menu class="choose-steps-header">
              <template slot="login">
                <a :href="getUrlByRoute('login')" class="blue-button" hreflang="en">
                  Login
                </a>
              </template>
              <template slot="register">
                <a :href="getUrlByRoute('register')" class="white-button" hreflang="en">
                  Create account
                </a>
              </template>
            </top-right-menu>
          </div>
        </div>
      </div>
    </header>
  </transition>
</template>

<script>
import stepsNavigation from '../../mixins/steps-navigation';
import appMixin from '../../mixins/app-mixin';
import downloadProduct from '../../mixins/download-product';

export default {
  name: 'header-page',

  mixins: [stepsNavigation, appMixin, downloadProduct],

  data() {
    return {
      isMounted: false,
      logotype: window.logotype,
    };
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    //
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  height: 70px;
  background-color: #3A58F9;
  align-items: center;
  z-index: 3000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  @media only screen and (max-width: 1198px) {
    display: none;
  }
}

.logo {
  width: 80px;
  height: 80px;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

a {
  text-decoration: none;
}

.logo {
  a {
    margin: auto 8px;

    svg path {
      fill-opacity: .5;
      transition: all .5s ease;
    }

    &:hover svg path {
      fill-opacity: 1;
    }
  }

  img {
    cursor: pointer;
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 3;
  position: relative;
  margin: 0 auto;
  max-width: 720px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #4D68FA;
  }

  a {
    text-transform: capitalize;
    border: 1px solid #4D68FA;
    border-radius: 3px;
    background-color: #3A58F9;
    padding: 8px 15px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    color: #fff;
    display: flex;
    cursor: pointer;
    z-index: 1;
    align-items: center;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #fff;
      color: #3A58F9;

      svg path {
        fill: #24D977;
      }
    }
  }

  .current {
    background-color: #fff;
    color: #3A58F9;
  }

  a svg {
    margin-right: 10px;

    path {
      fill: rgba(255, 255, 255, 0.3);
    }
  }

  .success svg path {
    fill: #24D977;
  }

  .current svg path {
    fill: rgba(41, 158, 231, 0.2);
  }
}

.right-content {
  height: 80px;
  display: flex;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  background: none;

  .register-person {
    width: 180px;
    margin: 0 10%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    svg {
      margin-left: 8px;
      cursor: pointer;
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      color: rgba(255, 255, 255, 0.5);
      margin: auto;
      transition: all .3s ease;
    }

    &:hover span {
      color: #fff;
    }

    svg path {
      transition: all .3s ease;
    }

    &:hover svg path {
      stroke-opacity: 1;
    }

    span span {
      color: #fff;
    }
  }
}

.header-buttons {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  button {
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 14px rgba(58, 88, 249, 0.3);
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: capitalize;
    transition: all .5s ease;
    color: #fff;
    cursor: pointer;
  }

  .login {
    background-color: transparent;
    margin-right: 20px;
  }

  .register {
    border-color: #fff;
    background-color: #fff;
    color: #3A58F9;

    &:hover {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .login:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border-color: #fff;
    background-color: #fff;
    color: #3A58F9;
  }
}

.nav-menu {
  display: flex;

  .submenu {
    top: 65px;
    right: -18px;

    .submenu {
      right: calc(100% + 10px);
      top: 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .steps {
    margin: auto 10%;
  }
}

@media only screen and (max-width: 920px) {
  .steps {
    margin: auto 5%;
  }
}

@media only screen and (max-width: 720px) {
  .logo {
    border-right: none;
  }
  .steps {
    display: none;
  }
  .right-content {
    margin-left: auto;
    border-left: none;
  }
}

.login-register-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}


</style>