<template>
    <transition name="slideUp">
        <footer v-show="isMounted">
            <!-- To Home button-->
            <a :href="getBackStepUrl()"
               v-if="isActive(steps.choose)"
               class="animated-button back-button"
               title="home page"
               itemprop="url"
               hreflang="en">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span class="arrow-icon arrow-left-icon"></span>
                Home
            </a>
            <!-- To Home button-->

            <!-- Preview logo button-->
            <el-button @click="showPreview"
                       v-if="isActive(steps.edit)"
                       v-loading="!states.buttons.preview.available"
                       :disabled="!states.buttons.preview.available"
                       type="primary"
                       class="button button-preview"
                       icon="el-icon-view"
            >Preview
            </el-button>
            <!-- Preview logo button-->

            <!-- Choose logo button-->
            <transition name="bounceUp">
                <a :href="getNextStepUrl()"
                   v-if="isActive(steps.choose) && buttons.nextButton.isVisible"
                   class="animated-button animate-button choose-logo-button"
                   title="choose logo"
                   itemprop="url"
                   hreflang="en">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                    Edit logo
                    <span class="arrow-icon arrow-right-icon"></span>
                </a>
            </transition>
            <!-- Choose logo button -->

            <!--Download button-->
            <el-dropdown
                    @command="clickDownload"
                    v-if="isActive(steps.edit)"
                    v-loading="!states.buttons.download.available"
                    :disabled="!states.buttons.download.available"
                    trigger="click">
                <el-button type="primary" class="button button-download">
                    Download<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="products.logotype" class="download-logotype">
                        <i class="fa fa-picture-o" aria-hidden="true"></i> Logotype
                    </el-dropdown-item>
                    <el-dropdown-item :command="products.package" class="download-package">
                        <i class="fa fa-archive" aria-hidden="true"></i> Full design package
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!--Download button-->
        </footer>
    </transition>
</template>

<script>
    import stepsNavigation from '../../mixins/steps-navigation';
    import appMixin from '../../mixins/app-mixin';
    import downloadProduct from '../../mixins/download-product';

    export default {
        name: 'footer-page',

        mixins: [stepsNavigation, appMixin, downloadProduct],

        data() {
            return {
                isMounted: false,
                logotype: window.logotype,
                product: null,
                buttons: {
                    nextButton: {
                        isVisible: true,
                    },
                },
            };
        },

        mounted() {
            this.isMounted = true;
        },

        created() {
            EventBus.$on('editor.logotype.saved', () => {
                // Hide preloader on buttons
                switch (this.product) {
                    case this.products.logotype:
                        this.downloadLogo(this.logotype);
                        break;
                    case this.products.package:
                        this.downloadPackage(this.logotype);
                        break;
                }
            });

            EventBus.$on('footer.button-next.hide', () => {
                this.buttons.nextButton.isVisible = false;
            });

            EventBus.$on('footer.button-next.show', () => {
                this.buttons.nextButton.isVisible = true;
            });
        },

        beforeDestroy() {
            EventBus.$off('logotype.preview.set');
            EventBus.$off('editor.logotype.saved');
        },

        methods: {
            showPreview() {
                // Set preloader to "Preview" button
                this.states.buttons.preview.available = false;

                // Emit event for showing modal
                EventBus.$emit('editor.preview.show');
            },

            getBackStepName() {
                if (this.currentStep) {
                    if (this.currentStep.name === 'logo.show.choose') {
                        return 'Home';
                    }

                    if (this.currentStep.name === 'logo.edit') {
                        return 'New logo';
                    }

                    if (this.currentStep.name === 'download.package') {
                        return 'Edit logo';
                    }
                }

                return 'New logo';
            },

            getBackStepUrl() {
                if (this.currentStep) {
                    if (this.currentStep.name === 'logo.show.choose') {
                        return route('index');
                    }

                    if (this.currentStep.name === 'logo.edit') {
                        return route('logo.show.choose');
                    }
                }

                return route('logo.show.choose');
            },

            getNextStepUrl() {
                if (this.currentStep.name === 'logo.show.choose') {
                    return _.find(this.steps, ['name', 'logo.edit']).route;
                }

                if (this.currentStep.name === 'logo.edit') {
                    return route('download.package', this.logotype.hash);
                }
            },

            clickDownload(product) {
                // Show preloader on buttons
                this.states.buttons.download.available = false;

                // Save current logo version
                EventBus.$emit('editor.logotype.save');

                switch (product) {
                    case this.products.logotype:
                        this.product = this.products.logotype;
                        break;
                    case this.products.package:
                        this.product = this.products.package;
                        break;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

    .svg-editor {
        .mobile-editor {
            footer {
                display: none;
            }
        }
    }

    footer {
        width: 100%;
        height: 70px;
        position: fixed;
        bottom: 0;
        background: #fff;
        box-shadow: 0 -6px 14px rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        padding: 0 20px;
        z-index: 2;
        justify-content: flex-end;
    }

    .button {
        display: flex;
        height: 45px;
        min-width: 120px;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        outline: none;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);

        &-download {
            background: #45ca82;
            border: #43c250 1px solid;

            &:hover {
                background: #4aa976;
            }
        }

        &-preview {
            margin-right: 15px;
            padding: 0 15px;
            color: #FFFFFF;
            border: #6319a5;
            background: #7719be;

            &:hover {
                background: #8B19D1;
            }
        }
    }

    .previous-step {
        width: 160px;
        height: 45px;
        border: 1px solid rgba(58, 88, 249, 0.2);
        border-radius: 4px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 16px;
        text-transform: capitalize;
        display: flex;
        color: #3A58F9;
        align-items: center;
        transition: all .2s ease;
        cursor: pointer;

        @media (max-width: 767px) {
            width: 150px;
        }
    }

    .next-step {
        width: 160px;
        height: 45px;
        border: 1px solid rgba(58, 88, 249, 0.2);
        border-radius: 4px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 16px;
        text-transform: capitalize;
        display: flex;
        color: #3A58F9;
        align-items: center;
        transition: all .2s ease;
        cursor: pointer;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);

        &.active {
            background-color: #2743DE;
            border: 1px solid rgba(58, 88, 249, 0.2);
            color: #fff;

            &:hover span {
                background: rgba(255, 255, 255, 0.1);
                border-color: rgba(255, 255, 255, 0.1);
            }

            span svg path {
                stroke: #fff;
            }
        }

        @media (max-width: 767px) {
            width: 150px;
        }
    }

    .previous-step span, .next-step span {
        width: 45px;
        height: 45px;
        border: 1px solid #3A58F9;
        border-radius: 4px;
        display: flex;
    }

    .previous-step span svg, .next-step span svg {
        margin: auto;
    }

    .previous-step span svg path, .next-step span svg path {
        stroke: #3A58F9;
    }

    .previous-step span {
        margin-right: 18px;
    }

    .next-step {
        flex-direction: row-reverse;

        span {
            margin-left: 18px;
        }
    }

    .previous-step:hover, .next-step:hover {
        background-color: #2743DE;
        border: 1px solid rgba(58, 88, 249, 0.2);
        color: #fff;
    }

    .previous-step:hover span, .next-step:hover span {
        background: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.1);
    }

    .previous-step:hover span svg path, .next-step:hover span svg path {
        stroke: #fff;
    }

    a {
        text-decoration: none;
    }

    .animated-button {
        text-transform: none;
        letter-spacing: 0;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            margin-bottom: 0;
        }
    }

    .back-button {
        margin-left: 0;
        margin-right: auto;
    }
</style>